export const blogPage = {
  alignItems: 'center',
  w: 'full',
  h: 'full',
};

export const blogContainer = {
  justifyContent: 'center',
  alignItems: 'center',
  h: 'full',
};
